import { Item } from '../api/items/types';

export function getItemSubPath({ gender_category, raw_data }: Item) {
  return (
    raw_data?.product_group == 'Smycken' ? 'jewelry'
    : gender_category == 'Kvinna' ? 'watchesf'
    : gender_category == 'Herr' ? 'watchesm'
    : 'watches'
    );
}

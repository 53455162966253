export default {
  "general-h1": `
    font-family: Playfair Display;
    font-size: 28px;
    font-weight: bold;
  `,
  "general-h2": `
    font-family: Brandon Grotesque;
    font-size: 26px;
    font-weight: bold;
  `,
  "general-h3": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 16px;
  `,
  "general-body1": `
    font-family: Brandon Grotesque;
    font-size: 16px;
  `,
  "general-body2": `
    font-family: Brandon Grotesque;
    font-size: 14px;
  `,
  "general-button1": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 14px;
  `,
  "general-button2": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 12px;
  `,
  "general-subtitle1": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 12px;
  `,
  "general-subtitle2": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 12px;
  `,
  "general-link": `
    font-family: Brandon Grotesque;
    font-size: 16px;
    text-decoration: underline;
  `,
  "detail-h1": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 12px;
  `,
  "detail-body1": `
    font-family: Brandon Grotesque;
    font-size: 16px;
  `,
  "detail-body2": `
    font-family: Brandon Grotesque;
    font-size: 14px;
  `,
  "detail-name": `
    font-family: Brandon Grotesque;
    font-size: 26px;
    font-weight: bold;
  `,
  "detail-brand": `
    font-family: Brandon Grotesque;
    font-size: 16px;
  `,
  "detail-price": `
    font-family: Brandon Grotesque;
    font-size: 26px;
    font-weight: bold;
  `,
  "detail-ogprice": `
    font-family: Brandon Grotesque;
    font-size: 20px;
    font-weight: bold;
    text-decoration: line-through;
  `,
  "detail-link": ``,
  "detail-condition": ``,
  "nav-h1": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 16px;
  `,
  "nav-button1": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 14px;
  `,
  "nav-button2": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 12px;
  `,
  "nav-subtitle1": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 12px;
  `,
  "nav-body1": `
    font-family: Brandon Grotesque;
    font-size: 12px;
    font-weight: bold;
  `,
  "nav-search": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 14px;
  `,
  Body2: `
    font-family: Brandon Grotesque;
    font-size: 14px;
  `,
  "listing-price1": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 24px;
  `,
  "listing-price2": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 16px;
    text-decoration: line-through;
  `,
  "listing-title": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 20px;
  `,
  "listing-subtitle": `
    font-family: Brandon Grotesque;
    font-size: 16px;
  `,
  "listing-condition": ``,
  "drawer-title": `
    font-family: Brandon Grotesque;
    font-weight: bold;
    font-size: 16px;
  `,
}

import colors from './colors.json';
import { Styles } from './types';
import LogoCombined from '../assets/logo-combined.svg';
import HomeTitleBg from '../assets/icons/homeTitleBg.svg';

const styles: Styles = {
  "layout": ({ isMobile }) => `
    .home {
      padding: ${isMobile ? '40px 20px' : '40px 80px'};
      .texts-container {
      }
      .title-container {
        background-image: url(${HomeTitleBg});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: -24px 0 72px 0;
        padding: 24px;
        .home-title {
        }
        .home-title-line {
          height: 0px;
          display: none;
        }
      }
    }
    .side-menu-title {
      &:after {
        height: 0px;
      }
    }
    .go-back {
      .top-logo-container {
        padding-top: 0px;
      }
      .partner-top-logo {
        width: 166px;
      }
      .recrql-top-logo {
        width: 60px;
        margin-left: 20px;
        bottom: 0px;
      }
    }
    .usps {
      background-color: #000;
      .typography {
        color: #fff;
        text-transform: uppercase;
      }
    }
    .search-drawer > .MuiPaper-root {
      margin-top: 0;
      position: absolute;
      top: 0;
      justify-content: center;
      display: flex;
      height: ${isMobile ? '266.94px' : '184px'};
      background-image: url(${LogoCombined});
      background-repeat: no-repeat;
      ${isMobile ? `
        background-position: 50% 45%;
        ` : `
        align-items: center;
        background-position: 5% 50%;
        background-size: 20%;
      `}
      .close-button {
        position: absolute;
        right: ${isMobile ? '24px' : '78px'};
        ${isMobile ? `top: 36px;` : ``}
        border-radius: 100px;
        padding: 10px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .search-input {
      padding: 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      ${isMobile ? `
        height: 24px;
      ` : `
        height: 36px;
        width: 336px;
      `}
      ${isMobile ? `margin: 182.94px 24px 0 24px;` : ``}
      .input-container {
        margin-left: 0px;
        input {
          background-color: rgba(0,0,0,0);
        }
      }
    }
    .item-row {
      .img-placeholder {
        background-color: rgba(0,0,0,0) !important;
      }
      .item-row-title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 95px;
        > .typography-listing-subtitle {
          margin-bottom: 8px;
          text-transform: uppercase;
        }
        > .typography-listing-title {
          text-transform: uppercase;
          overflow: hidden;
        }
      }
      .price-container {
        height: 70px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  `,
  "logo": `
    &.top-logo-container {
      padding-top: 42px;
      .recrql {
        margin-left: 35px;
        position: relative;
        bottom: -5px;
      }
    }
    &.bottom-logo-container {
      .recrql {
        margin-left: 20px;
        position: relative;
        bottom: -3px;
      }
    }
  `,
  "nav": {
    "superContainer": `
      .mobile-sublogo {
        background: none;
      }
    `,
    "desktopMainContainer": `
      height: 184px;
      background-color: white;
      .external-link {
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
    `,
    "flexContainer": ``,
    "navLogo": ``,
    "linksContainer": `
      text-transform: uppercase;
    `,
  },
  "button": `
    min-width: 138px;
    .start-icon {
      margin-right: 10px;
      color: #fff;
    }
  `,
  "breadcrumbNavLink": `
    text-transform: uppercase;
  `,
  "itemsView": {
    "title": `
      margin-top: 30px;
    `,
    "topContainer": `
      display: flex;
      align-items: center;
      flex-direction: column;
    `
  },
  "itemFiltersDesktopView": `
    background-color: ${colors.backgroundSecondary};
    justify-content: center;
    padding: 25px;
    border-bottom: 0;
    align-items: center;
    text-transform: uppercase;
  `,
  "dropdownIconColor": colors.primaryAccent,
  "itemDetail": ({ isSmallScreen }) => `
    .title-container {
      flex-direction: column;
      align-items: flex-start;
      ${isSmallScreen ? `
        align-items: center;
        .title {
          margin-bottom: 0px;
        }
      ` : ''}
      .title {
        margin-right: 0px;
      }
      .ref {
        margin-top: 8px;
        margin-bottom: 36px;
      }
    }
    .specification-container {
      margin-top: 84px;
      .title {
        text-transform: uppercase;
      }
      .subtitle {
        text-transform: uppercase;
        color: ${colors.primaryAccent};
      }
    }
  `,
  "divider": `
    height: 0.5px;
  `,
  "carousel": `
    .selector-ellipse {
      background-color: ${colors.textSecondary};
    }
    .selected-ellipse {
      background-color: ${colors.textSecondary};
    }
    .swiper-slide {
      background-color: rgba(0,0,0,0);
    }
  `,
  "expander": `
    .icon {
      color: ${colors.primaryAccent};
    }
    .title > .typography {
      text-transform: uppercase;
      color: ${colors.primaryAccent};
    }
  `,
}

export default styles;
